import React, { FunctionComponent, useEffect, useState } from 'react';
import EventsList from './components/events-list/events-list';
import EventsPreview from './components/events-preview/events-preview';
import { DATA_QA } from './helpers/data-qa-attributes';
import './styles/Page.scss';
import ConfigureDateSection from './components/configure-date/configure-date';
import RoundedBlueButton from '../../Partials/design-components/buttons/rounded-blue';
import MultisportHttpService from '../../../services/rest/multisport-http-service';
import { responseToMultisportModel } from './models/sport.mapper';
import MultisportModel from './models/sport.model';
import RemovePreviewEntities from './components/modals/remove-preview-entity';
import SavePreviewEntities from './components/modals/save-preview-entities';
import { connect } from 'react-redux';
import {
	loadMultisportEntities,
	pushDataToModal,
	resetMultisportData,
	setMultisportConfigurationDate,
} from '../../../store/action-creators/multisport-action-creator';
import { setTopEventSectionAsFirstElement } from './helpers/utils';
import { MultisportModalsType } from './components/modals/constants';
import { useTranslation } from 'react-i18next';
import { isDateObjValid } from '../../../global-helpers/global-dates.helper';
import { toast } from 'react-toastify';

type Props = {
	loadMultisportEntities: (data: MultisportModel[]) => void;
	previewData: MultisportModel[];
	displaySaveModal: (changedConfigDate?: Date) => void;
	setConfigurationDate: (date: Date) => void;
	resetMultisportDataRedux: () => void;
};

const MultisportContainer: FunctionComponent<Props> = ({
	loadMultisportEntities,
	previewData,
	displaySaveModal,
	setConfigurationDate,
	resetMultisportDataRedux,
}) => {
	const { t } = useTranslation();
	const [configurationDateAsObj, setConfigurationDateAsObj] = useState(new Date());
	const [isPreviewEventsLoading, setPreviewEventsLoading] = useState<boolean>(false);
	const [initialFetchedData, setInitialFetchedData] = useState<MultisportModel[]>([]);
	const areInitialFetchedDataAndPreviewDataEqual = JSON.stringify(initialFetchedData) === JSON.stringify(previewData); //_.isEqual not working here :/

	const fetchPreviewEvents = (date: Date | null, changeDate: boolean = false) => {
		if (isDateObjValid(date)) {
			setPreviewEventsLoading(true);
			MultisportHttpService.getPreviewEventsByDate(date!!)
				.then((response) => {
					if (response && response.data) {
						const result = response.data.map((responseSportData: Array<any>) => responseToMultisportModel(responseSportData));
						loadMultisportEntities(result);
						setInitialFetchedData(structuredClone(result));
					} else {
						loadMultisportEntities([]);
					}
				})
				.catch(() => toast.error(t('multisport_config_error_fetch')))
				.finally(() => {
					setPreviewEventsLoading(false);
					changeDate && setConfigurationDate(date || new Date());
				});
		}
	};

	const detectChangesAction = (date: Date | null) => {
		if (!areInitialFetchedDataAndPreviewDataEqual) {
			displaySaveModal(date || undefined);
		} else {
			setConfigurationDateAsObj(date || new Date());
			setConfigurationDate(date || new Date());
		}
	};

	useEffect(() => {
		fetchPreviewEvents(configurationDateAsObj);
	}, [configurationDateAsObj]);

	useEffect(() => {
		return () => resetMultisportDataRedux();
	}, []);

	return (
		<>
			<div id={DATA_QA.MULTISPORT_WRAPPER}>
				<EventsList />
				<div id={DATA_QA.RIGHT_SECTION} className={isPreviewEventsLoading ? 'loading-overlay' : ''}>
					<ConfigureDateSection selectConfigureDate={detectChangesAction} date={configurationDateAsObj} />
					{previewData.length > 0 ? (
						setTopEventSectionAsFirstElement(previewData).map((previewEvents) => (
							<EventsPreview key={previewEvents.sport} previewEvents={previewEvents} />
						))
					) : (
						<div className={DATA_QA.EVENTS_PREVIEW_WRAPPER}>
							<h3> {t('automatic_multisport_data')} </h3>
						</div>
					)}
				</div>

				<RemovePreviewEntities />
				<SavePreviewEntities actionAfterCancel={(date: Date) => fetchPreviewEvents(date, true)} actionAfterSave={setInitialFetchedData} />
			</div>
			<div id={DATA_QA.FOOTER_ACTIONS_WRAPPER}>
				<RoundedBlueButton title={t('save_changes')} text={t('save_changes')} onClickAction={displaySaveModal} />
			</div>
		</>
	);
};

function mapStateToProps(state: any) {
	return {
		previewData: state.multisportConfiguration.previewData || [],
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		loadMultisportEntities: (data: MultisportModel[]) => dispatch(loadMultisportEntities(data)),
		displaySaveModal: (changedConfigDate?: Date) =>
			dispatch(pushDataToModal(MultisportModalsType.Save, isDateObjValid(changedConfigDate) ? { changedConfigDate } : null)),
		setConfigurationDate: (date: Date) => dispatch(setMultisportConfigurationDate(date)),
		resetMultisportDataRedux: () => dispatch(resetMultisportData()),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MultisportContainer);
